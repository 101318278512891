import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const UploadCloud = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={78}
      height={70}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path stroke={color} strokeLinecap="round" d="M50.975 56.86h-26" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="m29.85 39.596 8.52-8.442 8.538 8.442M38.37 31.154V52.04"
      />
      <path
        stroke={color}
        d="M23.605 46.036h-11.29c-9.236 0-10.263-14.205 0-14.205-6.157-6.088 6.159-18.265 14.369-12.177C29.763 3.42 59.526 5.448 56.447 22.698c17.447 0 18.474 23.338 5.132 23.338h-9.237"
      />
    </svg>
  );
});

UploadCloud.displayName = 'UploadCloud';

export default UploadCloud;
