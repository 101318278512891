import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Share = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      ref={ref}
      fill="none"
      {...otherProps}
    >
      <circle
        cx={16.871}
        cy={6.339}
        r={2.633}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={16.871}
        cy={16.872}
        r={2.633}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={6.339}
        cy={11.605}
        r={2.633}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="m8.472 12.67 6.334 3.257M8.472 10.302l6.078-2.805"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Share.displayName = 'Share';

export default Share;
