import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const DataColumnChart = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect x={4.5} y={4.5} width={3} height={15} rx={0.5} stroke={color} />
      <rect x={10.5} y={8.5} width={3} height={11} rx={0.5} stroke={color} />
      <rect x={16.5} y={12.5} width={3} height={7} rx={0.5} stroke={color} />
    </svg>
  );
});

DataColumnChart.displayName = 'DataColumnChart';

export default DataColumnChart;
