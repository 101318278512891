import { trackEvent } from 'helpers/analytics';

export * from './projectView';
export * from './projectTimelineView';

const openContentDrawerConfirm = (component, props) => ({
  type: 'CONTENT_DRAWER_OPEN',
  component,
  props,
});

export const closeContentDrawerConfirm = () => ({
  type: 'CONTENT_DRAWER_CLOSE',
});

export const setSnackbar = snackbar => ({
  type: 'SET_SNACKBAR',
  snackbar,
});

export const setLastFilter = lastFilter => ({
  type: 'PROJECTS_VIEW_SET_LAST_FILTER',
  lastFilter,
});

export const setLastEntryPath = path => ({
  type: 'SET_LAST_ENTRY_PATH',
  path,
});

export const turnConfettiOn = props => ({
  type: 'CONFETTI_ON',
  props,
});

export const turnConfettiOff = () => ({
  type: 'CONFETTI_OFF',
});

export const setConfettiPartyOver = () => ({
  type: 'CONFETTI_PARTY_IS_OVER',
});

export const openSearchModal = () => ({
  type: 'OPEN_SEARCH_MODAL',
});

export const closeSearchModal = () => ({
  type: 'CLOSE_SEARCH_MODAL',
});

export const openTimeTrackedForm = (props = {}) => ({
  type: 'TIME_TRACKED_FORM_OPEN',
  props,
});

export const closeTimeTrackedForm = () => ({
  type: 'TIME_TRACKED_FORM_CLOSE',
});

export const closeContentDrawer = () => (dispatch) => {
  dispatch(closeContentDrawerConfirm());
};

export const openContentDrawer = (component, props) => (dispatch) => {
  trackEvent(`Open drawer ${component}`);
  dispatch(openContentDrawerConfirm(component, props));
};

export const showConfetti = (callback, props = { duration: 2500 }) => (dispatch) => {
  const { duration, ...otherProps } = props;

  dispatch(turnConfettiOn(otherProps));
  setTimeout(() => {
    dispatch(turnConfettiOff());
    if (callback) callback();
  }, duration);
};
