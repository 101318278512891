import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: 'up' | 'down' | 'left' | 'right'
  strokeWidth?: number
};

const rotationMap = {
  up: -90,
  down: 90,
  left: 180,
  right: 0,
};

const SmallArrow = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color = 'currentColor', direction = 'right', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      style={{
        transformOrigin: 'center',
        transition: 'transform 0.25s',
        transform: `rotate(${rotationMap[direction]}deg)`,
      }}
      ref={ref}
      {...otherProps}
    >
      <path
        d="M9.83 4.335l3.504 3.495-3.504 3.503M13.334 7.83H2.667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

SmallArrow.displayName = 'SmallArrow';

export default SmallArrow;
