import { forwardRef } from 'react';

import type { SVGProps } from 'react';

import { palette } from "theme";

type Props = SVGProps<SVGSVGElement> & { disabled?: boolean };

const Attachment = forwardRef<SVGSVGElement, Props>(({ disabled = false, ...props }, ref) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M26.616 18.654V7.962h.206a4 4 0 013.075 1.442l5.35 6.432a4 4 0 01.924 2.558v.26h-9.555z"
      fill={disabled ? '#CECFD0' : palette.status.blue[3]}
    />
    <path
      d="M30.669 18.654a4 4 0 01-4-4V7.962H13.633a3.622 3.622 0 00-3.623 3.635v24.806a3.622 3.622 0 003.623 3.635h18.915a3.622 3.622 0 003.623-3.635V18.654h-5.502z"
      fill={disabled ? '#8F9194' : palette.primary.main}
    />
  </svg>
));

Attachment.displayName = 'Attachment';

export default Attachment;
