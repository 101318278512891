import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Customize = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={9.344}
        cy={7.502}
        r={2.5}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M3.793 7.502h2.89m5.28 0h7.932M19.895 16.5h-1.338m-5.638 0H3.793"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={15.463}
        cy={16.5}
        r={2.5}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Customize.displayName = 'Customize';

export default Customize;
