import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const DataFinancialCard = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5h8a3 3 0 013 3v8a3 3 0 01-3 3H8a3 3 0 01-3-3V8a3 3 0 013-3zM4 8a4 4 0 014-4h8a4 4 0 014 4v8a4 4 0 01-4 4H8a4 4 0 01-4-4V8zm9.148 5.157a.927.927 0 01.336.736.922.922 0 01-.374.767c-.245.193-.605.29-1.079.29-.482 0-.856-.121-1.123-.363-.261-.242-.392-.584-.392-1.025H9c0 .694.22 1.25.661 1.671.445.421 1.08.67 1.902.747V17h.992v-1.025c.77-.067 1.368-.284 1.796-.652.433-.368.649-.848.649-1.44 0-.316-.056-.591-.168-.826a1.885 1.885 0 00-.48-.62 3.175 3.175 0 00-.768-.484c-.303-.14-.721-.293-1.253-.458-.533-.164-.911-.338-1.136-.52a.916.916 0 01-.33-.736c0-.34.112-.601.337-.784.224-.182.536-.273.935-.273.403 0 .722.128.954.384.238.252.356.6.356 1.04h1.51c0-.686-.198-1.244-.593-1.671-.395-.431-.954-.69-1.678-.778V7h-.998v1.146c-.72.07-1.289.293-1.709.668-.42.375-.63.853-.63 1.435 0 .859.485 1.507 1.454 1.945.29.134.698.28 1.222.437.524.154.898.33 1.123.526z"
        fill={color}
      />
    </svg>
  );
});

DataFinancialCard.displayName = 'DataFinancialCard';

export default DataFinancialCard;
